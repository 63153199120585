import React, { useContext, useEffect } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import './index.css'
import { ImportacaoContext } from '../../../../contexts/importacao'
import { Box } from '@mui/material'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import ReactLoading from 'react-loading'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'

const DepositoJudicialIdentificacao = () => {
    const { showMenuConfiguracoes, showMeuPerfil, loadingDownload, alterColumnVisibility } = useContext(LegalInsightsCrudContext)
    const {  load }  = useContext(ImportacaoContext)
    const { user } = useContext(AuthContext)

    useEffect(()=> { 
        alterColumnVisibility({ id: false })
    },[])


    const conciliacaoMock = [
        {id: '1', processo: '0016600-60.2001.5.02.0332', vara: '365', status: '', honorariosPact: '', checklist: '', documentos: '', apolice: ''}
    ]
    
    const widthPadrao = 250
    const columns = [{
        "field": "id",
        "hide": true,
        "width": 80,
    },
    
    {
        "field": "empresa",
        "headerName": "Empresa",
        "width": widthPadrao,
        "hide": false
    },
    
    {
        "field": "cnpj",
        "headerName": "CNPJ",
        "width": widthPadrao,
        "editable": false,
    },
    {
        "field": "natureza",
        "headerName": "Natureza",
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "processo",
        "headerName": "Processo",
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "tribunal",
        "headerName": "Tribunal",        
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "vara",
        "headerName": "Vara",        
        "width": widthPadrao,
        "editable": false
    },

    {
        "field": "comarca",
        "headerName": "Comarca",
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "status",
        "headerName": "Status",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "dataArquivamento",
        "headerName": "Data do Arquivamento",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "momentoProcessual",
        "headerName": "Momento Processual",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "instancia",
        "headerName": "Instância",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "tipoConta",
        "headerName": "Tipo de Conta",        
        "width": widthPadrao,
        "editable": false
    },
    {
        "field": "oQueFazer",
        "headerName": "O que Fazer",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "dataDepositoAberturaContaJud",
        "headerName": "Data do Depósito/Abertura Conta JUD",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "saldoAtualizado",
        "headerName": "Saldo Atualizado",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "dataSaldo",
        "headerName": "Data do Saldo",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "valorRecuperacao",
        "headerName": "Valor Recuperação",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "valorParaGestao",
        "headerName": "Valor Para Gestão",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "banco",
        "headerName": "Banco",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "idConta",
        "headerName": "ID Conta",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "coBase",
        "headerName": "CO Base",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "coEstab",
        "headerName": "CO Estab",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "coEmpregado",
        "headerName": "CO Empregado",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "dtaOrigemDocumento",
        "headerName": "DTA Origem Documento",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "agencia",
        "headerName": "Agência",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "operacao",
        "headerName": "Operação",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "conta",
        "headerName": "Conta",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "dv",
        "headerName": "DV",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "faseProjeto",
        "headerName": "Fase do Projeto",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "dataDoPeticao",
        "headerName": "Data do Petição",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "proximaFase",
        "headerName": "Próxima Fase",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "dataDaPeticao",
        "headerName": "Data da Petição",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "numeroApolice",
        "headerName": "Número da Apólice",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "vencimentoApolice",
        "headerName": "Vencimento da Apólice",        
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "seguradora",
        "headerName": "Seguradora",
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "valorCreditado",
        "headerName": "Valor Creditado",
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "dataCredito",
        "headerName": "Data do Crédito",
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "contaCreditada",
        "headerName": "Conta Creditada",
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "andamentoProcessoAposProtocolo",
        "headerName": "Adamento do Processo Após o Protocolo",
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "statusFinal",
        "headerName": "Status Final",
        "width": widthPadrao,
        "editable": false
    }, {
        "field": "fee",
        "headerName": "Fee",
        "width": widthPadrao,
        "editable": false
    }
    ]



    return(
        <div className="modulo-deposito-judicial-container">
            <Header />   

            <div className="deposito-judicial-container">
                <div className="deposito-judicial-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('depositoJudicial', user?.perfil)}/>
                </div>

                <div className="deposito-judicial-conteudo">
                    <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            <div className="conteudo-header">                                
                            </div>                                                        
                            <div className="data-grid-meus-processos">

                                {load? 
                                    <div className="loadProcessos">
                                        <ReactLoading type='spin' color='#4e1975' />
                                    </div> 
                                :
                                    <Box sx={{ height: '80vh', width: '100%' }}>
                                        <DataGridCustom 
                                            column={columns} 
                                            rows={conciliacaoMock} 
                                            selection={false}              
                                        />
                                    </Box>
                                }

                                {loadingDownload && 
                                    <div className="loadProcessos">
                                     <ReactLoading type='spin' color='#4e1975' />
                                    </div>                                     
                                }    
                            </div>
                    </div>
                </div>

                <div className="deposito-judicial-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}

export default DepositoJudicialIdentificacao
