import React, { useContext } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import './index.css'
import { Box } from '@mui/material'
import ReactLoading from 'react-loading'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import GridSubstituicao from './GridSubstituicao'

const DepositoJudicialSubstituicao = () => {
    const { showMenuConfiguracoes, showMeuPerfil, loadingDownload } = useContext(LegalInsightsCrudContext)
    const { user, load } = useContext(AuthContext)

    return(
        <div className="modulo-deposito-judicial-container">
            <Header />   

            <div className="deposito-judicial-container">
                <div className="deposito-judicial-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('depositoJudicial', user?.perfil)}/>
                </div>

                <div className="deposito-judicial-conteudo">
                    <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            <div className="conteudo-header"> 
                                
                            </div>                                                        
                            <div className="data-grid-meus-processos">

                                {load? 
                                    <div className="loadProcessos">
                                        <ReactLoading type='spin' color='#4e1975' />
                                    </div> 
                                :
                                    <Box sx={{ height: '80vh', width: '100%' }}>
                                        <GridSubstituicao   />
                                    </Box>
                                }

                                {loadingDownload && 
                                    <div className="loadProcessos">
                                     <ReactLoading type='spin' color='#4e1975' />
                                    </div>                                     
                                }    
                            </div>
                    </div>
                </div>

                <div className="deposito-judicial-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}

export default DepositoJudicialSubstituicao
