import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import { showToast } from '../../utils/Compartilhados/CustomToast';

export const DepositoJudicialContext = createContext({})

const DepositoJudicialProvider = ({ children }) => {
    const [levantamento, setLevantamento] = useState([])
    const [substituicao, setSubstituicao] = useState([])
    const [identificacao, setIdentificacao] = useState([])
    const [urlBackEnd ] = useState(()=> process.env.REACT_APP_PACT_API )
    
    const getLevantamentos = async () =>{
        try {
            const response = await axios.get(`${urlBackEnd}/depositoJudicial/levantamento/buscarTodosProcessosRecuperacao`)
            .catch( (error) => {
                showToast('warning', error.message)
            } )

            if(response.status === 200){
                setLevantamento(response.data)
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const getSubstituicao = async () =>{
        try {
            const response = await axios.get(`${urlBackEnd}/depositoJudicial/substituicao/buscarTodosProcessosSubstituicao`)
            .catch( (error) => {
                showToast('warning', error.message)
            } )

            if(response.status === 200){
                setSubstituicao(response.data)
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const atualizarLevantamentoPorIdProcessoEValidaArquivo = async (idProcesso, payload, nomeColuna ) => {
        try {
            const response = await axios.put(`${urlBackEnd}/depositoJudicial/levantamento/atualizarLevantamentoPorIdProcessoEValidaArquivo`,{
                idProcesso: idProcesso,
                payload: payload,
                nomeColuna: nomeColuna
            })
            .catch( (error) => {
                showToast('warning', error.message)
            } )

            if(response.status === 200){
                return                 
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    const atualizaSubstituicaoPorIdProcesso = async (idProcesso, payload) => {
        try {
            const response = await axios.put(`${urlBackEnd}/depositoJudicial/substituicao/atualizarSubstituicaoPorIdProcesso`,{
                idProcesso: idProcesso,
                payload: payload
            })
            .catch( (error) => {
                showToast('warning', error.message)
            } )
            
            if(response.status === 200){
                return                 
            }

            if(response.status !== 200){
                showToast('warning', response.data.message)
            }
        } catch (error) {
            showToast('warning', error.message)
        }
    }

    return (
        <DepositoJudicialContext.Provider value={{         
            levantamento, setLevantamento,
            substituicao, setSubstituicao,
            identificacao, setIdentificacao,

            getLevantamentos, getSubstituicao, 
            atualizarLevantamentoPorIdProcessoEValidaArquivo,
            atualizaSubstituicaoPorIdProcesso
        }}>
            {children}
        </DepositoJudicialContext.Provider>
    )
}

DepositoJudicialProvider.propTypes = {
    children: PropTypes.node,
}

export default DepositoJudicialProvider;